









import Component from 'vue-class-component';
import Vue from 'vue';
import RsJobsHeader from '@/modules/jobs/components/main/header.vue';
import {LOCALSTORAGE} from '@/shared/constants/LOCALSTORAGE';
import {API_SERVICE} from '@/shared/api/api.service';
import {IApiReferralCodeData} from '@/shared/models/IApiReferralCodeData';

@Component({
  components: {RsJobsHeader},
  metaInfo: {
    title: 'Jobs'
  },
})
export default class Jobs extends Vue {

  code = '';

  mounted(){
    this.code = (this.$route.query.code || '') as string;
    if (this.code) {
      localStorage.setItem(LOCALSTORAGE.REFERRAL, this.code);
      API_SERVICE.referral.getReferralCodeData(this.code)
        .then((data: IApiReferralCodeData) => {
          if(!data.valid){
            localStorage.removeItem(LOCALSTORAGE.REFERRAL);
          }
        })
        .catch(() => {
          localStorage.removeItem(LOCALSTORAGE.REFERRAL);
        })
    }
  }
}
