







































import Component from 'vue-class-component';
import Vue from 'vue';
import RsButton from '@/shared/components/buttons/button.vue';
import {PublicJobsRouter} from '@/modules/jobs/router';
import {OnboardingRouter} from '@/modules/onboarding/router';

@Component({
  name: 'rs-jobs-header',
  components: {RsButton},
})
export default class JobsHeader extends Vue {
  OnboardingRouter = OnboardingRouter
  JobsRouter = PublicJobsRouter
}
